import {
  BigNumberConfig,
  TableColumnOptions,
  TableInputs,
  type Visualization,
  isBarLineInput,
  isBigNumberInput,
  isCandlestick,
  isHeatmapInput,
  isPieInput,
  isScatterInput,
  isTableInput,
  isXYInput,
} from "./schema";

export function updateTitle(viz: Visualization, title: string): Visualization {
  const options = structuredClone(viz.config.options);
  options.title = { ...(options.title ?? {}), text: title };
  return updateOptions(viz, options);
}

export function updateSubtitle(viz: Visualization, subtitle: string): Visualization {
  const options = structuredClone(viz.config.options);
  options.subtitle = { ...(viz.config.options.subtitle ?? {}), text: subtitle };
  return updateOptions(viz, options);
}

export function setSliceKey(viz: Visualization, key: string, type: string): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isPieInput(inputs)) return viz;

  inputs.config.slice = { ...inputs.config.slice, key, type };
  return updateInputs(viz, inputs);
}

export function removeSliceKey(viz: Visualization): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isPieInput(inputs)) return viz;

  delete inputs.config.slice;
  return updateInputs(viz, inputs);
}

export function setPieValueKey(viz: Visualization, key: string, type: string): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isPieInput(inputs)) return viz;

  inputs.config.value = { ...inputs.config.value, key, type };
  return updateInputs(viz, inputs);
}

export function removePieValueKey(viz: Visualization): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isPieInput(inputs)) return viz;

  delete inputs.config.value;
  return updateInputs(viz, inputs);
}

export function setXKey(viz: Visualization, key: string, type: string): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isXYInput(inputs)) return viz;

  inputs.config.x = { ...inputs.config.x, key, type };
  return updateInputs(viz, inputs);
}

export function removeXKey(viz: Visualization): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isXYInput(inputs)) return viz;

  delete inputs.config.x;
  return updateInputs(viz, inputs);
}

export function addYKey(viz: Visualization, key: string, type: string): Visualization {
  if (!isXYInput(viz.config.inputs)) return viz;
  const inputs = structuredClone(viz.config.inputs);

  inputs.config.y = [...(inputs.config.y ?? []), { key, type }];
  return updateInputs(viz, inputs);
}

export function changeYKey(viz: Visualization, oldKey: string, key: string, type: string): Visualization {
  if (!isXYInput(viz.config.inputs)) return viz;
  const inputs = structuredClone(viz.config.inputs);

  const oldIndex = inputs.config.y?.findIndex((yConfig) => yConfig.key === oldKey);

  if (oldIndex !== undefined && oldIndex >= 0 && inputs.config.y?.[oldIndex]) {
    inputs.config.y[oldIndex] = { ...inputs.config.y[oldIndex], key, type };
  }
  return updateInputs(viz, inputs);
}

export function setCandlestickKey(
  viz: Visualization,
  field: "open" | "high" | "low" | "close",
  value: ColumnTypePair | undefined,
): Visualization {
  if (!isCandlestick(viz.config.inputs)) return viz;
  const inputs = structuredClone(viz.config.inputs);

  inputs.config[field] = value ? { ...inputs.config[field], key: value.name, type: value.type } : value;
  return updateInputs(viz, inputs);
}

export function clearYKeys(viz: Visualization): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isXYInput(inputs)) return viz;

  inputs.config.y = [];
  return updateInputs(viz, inputs);
}

export function removeYKey(viz: Visualization, key: string): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isXYInput(inputs)) return viz;

  inputs.config.y = (inputs.config.y ?? []).filter((y) => y.key !== key);
  return updateInputs(viz, inputs);
}

export function setZKey(viz: Visualization, key: string, type: string): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isScatterInput(inputs)) return viz;

  inputs.config.z = { ...inputs.config.z, key, type };
  return updateInputs(viz, inputs);
}

export function removeZKey(viz: Visualization): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isScatterInput(inputs)) return viz;

  delete inputs.config.z;
  return updateInputs(viz, inputs);
}

export function addBarLineKey(viz: Visualization, key: string, type: string): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isBarLineInput(inputs)) return viz;

  inputs.config.line = { ...inputs.config.line, key, type, position: "right" };
  return updateInputs(viz, inputs);
}

export function removeBarLineKey(viz: Visualization): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isBarLineInput(inputs)) return viz;

  delete inputs.config.line;
  return updateInputs(viz, inputs);
}

export function setLineGrouping(viz: Visualization, ignoreGrouping: boolean): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isBarLineInput(inputs) || !inputs.config.line) return viz;

  inputs.config.line = { ...inputs.config.line, ignoreGrouping };
  return updateInputs(viz, inputs);
}

export function addGroupingKey(viz: Visualization, key: string): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isXYInput(inputs)) return viz;

  inputs.config.grouping = { ...inputs.config.grouping, key };
  return updateInputs(viz, inputs);
}

export function removeGroupingKey(viz: Visualization): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isXYInput(inputs)) return viz;

  delete inputs.config.grouping;
  return updateInputs(viz, inputs);
}

export function addValueKey(viz: Visualization, key: string, type: string): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isHeatmapInput(inputs)) return viz;

  inputs.config.value = { ...inputs.config.value, type, key };
  return updateInputs(viz, inputs);
}

export function removeValueKey(viz: Visualization): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isHeatmapInput(inputs)) return viz;

  delete inputs.config.value;
  return updateInputs(viz, inputs);
}

export function updateXAxisLabel(viz: Visualization, title: string): Visualization {
  const options = structuredClone(viz.config.options);
  options.xAxis = {
    ...options.xAxis,
    title: { ...options.xAxis?.title, text: title },
  };
  return updateOptions(viz, options);
}

export function updateYAxisLabel(
  viz: Visualization,
  axis: { type: "array"; index: number } | { type: "barLine" },
  name: string,
): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  const yConfig =
    axis.type === "barLine"
      ? isBarLineInput(inputs)
        ? inputs.config.line
        : undefined
      : isXYInput(inputs)
        ? inputs.config.y?.[axis.index]
        : undefined;
  if (!yConfig) return viz;
  yConfig.name = name;
  return updateInputs(viz, inputs);
}

export function updateYAxisScale(
  viz: Visualization,
  axis: { type: "array"; index: number } | { type: "barLine" },
  logScale?: boolean,
): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  const yConfig =
    axis.type === "barLine"
      ? isBarLineInput(inputs)
        ? inputs.config.line
        : undefined
      : isXYInput(inputs)
        ? inputs.config.y?.[axis.index]
        : undefined;
  if (!yConfig) return viz;
  yConfig.logScale = logScale;
  return updateInputs(viz, inputs);
}

export function updateYAxisPosition(
  viz: Visualization,
  axis: { type: "array"; index: number } | { type: "barLine" },
  position?: "left" | "right",
): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  const yConfig =
    axis.type === "barLine"
      ? isBarLineInput(inputs)
        ? inputs.config.line
        : undefined
      : isXYInput(inputs)
        ? inputs.config.y?.[axis.index]
        : undefined;
  if (!yConfig) return viz;
  yConfig.position = position;
  return updateInputs(viz, inputs);
}

export function updateXAxisSort(viz: Visualization, direction: "none" | "asc" | "desc"): Visualization {
  const options = structuredClone(viz.config.options);
  if (!isXYInput(viz.config.inputs)) return viz;
  options.xAxis = { ...options.xAxis, reversed: direction === "none" ? undefined : direction === "desc" };
  if (viz.config.inputs.type === "heatmap") {
    options.yAxis = [
      {
        ...(options.yAxis as Record<string, unknown>[])?.[0],
        reversed: direction === "none" ? undefined : direction === "desc",
      },
    ];
  }

  return updateOptions(viz, options);
}

export function updateLegendEnabled(viz: Visualization, enabled: boolean): Visualization {
  const options = structuredClone(viz.config.options);
  options.legend = { ...options.legend, enabled };
  return updateOptions(viz, options);
}

export function updateLegendPosition(viz: Visualization, position: "top" | "bottom" | "left" | "right"): Visualization {
  const options = structuredClone(viz.config.options);
  if (position === "left" || position === "right") {
    options.legend = {
      ...options.legend,
      align: position,
      verticalAlign: "top",
      layout: "vertical",
      y: 30,
    };
  } else {
    options.legend = {
      ...options.legend,
      align: "left",
      verticalAlign: position,
      layout: "horizontal",
      y: null,
    };
  }
  if (viz.config.inputs.type === "heatmap") {
    options.colorAxis = { ...options.colorAxis, reversed: position === "left" || position === "right" };
  }
  return updateOptions(viz, options);
}

export function updateLegendReversed(viz: Visualization, reversed: boolean): Visualization {
  const options = structuredClone(viz.config.options);
  if (viz.config.inputs.type === "heatmap") {
    options.colorAxis = { ...options.colorAxis, reversed };
  } else {
    options.legend = { ...options.legend, reversed };
  }
  return updateOptions(viz, options);
}

export function updateColors(viz: Visualization, key: string, color: string): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  inputs.config.colors = { ...inputs.config.colors, [key]: color };
  return updateInputs(viz, inputs);
}

export function updateHeatmapColors(viz: Visualization, key: string, color: string): Visualization {
  const options = structuredClone(viz.config.options);
  options.colorAxis = { ...options.colorAxis, [key]: color };
  return updateOptions(viz, options);
}

export function updateHistogramBins(viz: Visualization, bins: number): Visualization {
  const options = structuredClone(viz.config.options);
  options.plotOptions = {
    ...options.plotOptions,
    histogram: { ...options.plotOptions?.histogram, binsNumber: bins },
  };
  if (bins <= 0) {
    delete options.plotOptions.histogram?.binsNumber;
  }
  return updateOptions(viz, options);
}

export function updateInputs(viz: Visualization, inputs: Visualization["config"]["inputs"]): Visualization {
  const copy = structuredClone(viz);
  return {
    ...copy,
    config: {
      ...copy.config,
      inputs,
    },
  };
}

export function updateOptions(viz: Visualization, options: Visualization["config"]["options"]): Visualization {
  const copy = structuredClone(viz);
  return {
    ...copy,
    config: {
      ...copy.config,
      options,
    },
  };
}

// Table

export function updateColumnVisibility(
  viz: Visualization,
  { columnName, isVisible }: { columnName: string; isVisible: boolean },
): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isTableInput(inputs)) return viz;
  const newColumns: TableColumnOptions = {
    ...inputs.config.columnOptions,
    [columnName]: {
      ...inputs.config.columnOptions[columnName],
      visible: isVisible,
      position: inputs.config.columnOptions[columnName]?.position ?? 0, // Ensure position is a number
    },
  };

  return updateInputs(viz, {
    ...inputs,
    config: { ...inputs.config, columnOptions: newColumns },
  });
}

export function updateCustomColumnLabel(
  viz: Visualization,
  { columnName, label }: { columnName: string; label: string },
): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isTableInput(inputs)) return viz;
  const newColumns: TableColumnOptions = {
    ...inputs.config.columnOptions,
    [columnName]: {
      ...inputs.config.columnOptions[columnName],
      customLabel: label,
      position: inputs.config.columnOptions[columnName]?.position ?? 0, // Ensure position is a number
    },
  };
  return updateInputs(viz, {
    ...inputs,
    config: { ...inputs.config, columnOptions: newColumns },
  });
}

export function reorderColumns(viz: Visualization, newOrder: string[]): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isTableInput(inputs)) return viz;

  // Ensure that newOrder contains all keys from columnOptions and nothing extra
  const columnOptionsKeys = Object.keys(inputs.config.columnOptions);
  if (newOrder.length !== columnOptionsKeys.length || !newOrder.every((key) => columnOptionsKeys.includes(key))) {
    console.error("New order does not match existing column options keys");
    return viz;
  }

  const newColumns = Object.fromEntries(
    newOrder.map((key, index) => {
      const config = inputs.config.columnOptions[key];
      return [key, { ...config, position: index }];
    }),
  );

  return updateInputs(viz, {
    ...inputs,
    config: { ...inputs.config, columnOptions: newColumns },
  });
}

/**
 * Sorts fields based on their positions defined in the input configuration.
 * If positions are not defined, it falls back to their original indices in the fields array.
 * @param {Array} fields - The array of fields.
 * @param {Object} config - The configuration object containing column options.
 * @returns {Array} - The sorted array of fields.
 */
export function sortFields(fields: ColumnTypePair[], config: TableInputs["config"]): ColumnTypePair[] {
  // Create a map of field names to their indices
  const fieldIndexMap = fields.reduce(
    (acc, field, index) => {
      acc[field.name] = index;
      return acc;
    },
    {} as Record<string, number>,
  );

  if (!config.columnOptions) {
    return fields;
  }

  return fields.slice().sort((a, b) => {
    const positionA = config.columnOptions[a.name]?.position ?? fieldIndexMap[a.name] ?? 0;
    const positionB = config.columnOptions[b.name]?.position ?? fieldIndexMap[b.name] ?? 0;
    return positionA - positionB;
  });
}

export function toggleSelectAll(viz: Visualization, fields: ColumnTypePair[]): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isTableInput(inputs)) return viz;

  const allFieldsSelected = areAllColumnsSelected(viz, fields);

  const newColumns: TableColumnOptions = Object.fromEntries(
    fields.map((field) => [
      field.name,
      {
        ...inputs.config.columnOptions[field.name],
        visible: allFieldsSelected ? false : true,
        position: inputs.config.columnOptions[field.name]?.position ?? 0, // Ensure position is a number
      },
    ]),
  );
  return updateInputs(viz, {
    ...inputs,
    config: { ...inputs.config, columnOptions: newColumns },
  });
}

export function selectAllIfEmpty(viz: Visualization, fields: ColumnTypePair[]): Visualization | null {
  const inputs = structuredClone(viz.config.inputs);
  if (!isTableInput(inputs)) return viz;
  // if there is no columnOptions, select all columns
  if (Object.keys(inputs.config.columnOptions).length === 0) {
    const newColumns: TableColumnOptions = Object.fromEntries(
      fields.map((field) => [
        field.name,
        {
          ...inputs.config.columnOptions[field.name],
          visible: true,
          selected: true,
          position: 0,
        },
      ]),
    );
    return updateInputs(viz, {
      ...inputs,
      config: { ...inputs.config, columnOptions: newColumns },
    });
  }

  return null;
}

export function areAllColumnsSelected(viz: Visualization, fields: ColumnTypePair[]): boolean {
  const inputs = viz.config.inputs;
  if (!isTableInput(inputs)) return false;

  const columnOptions = inputs.config.columnOptions;
  const fieldNames = fields.map((field) => field.name);

  if (fieldNames.length === 0) return false;

  return fieldNames.every((fieldName) => columnOptions[fieldName]?.visible === true);
}

// BIG NUMBER

export function updateBigNumberValue<K extends keyof BigNumberConfig>(
  viz: Visualization,
  property: K,
  value: BigNumberConfig[K],
): Visualization {
  const inputs = structuredClone(viz.config.inputs);
  if (!isBigNumberInput(inputs)) return viz;
  inputs.config[property] = value;
  return updateInputs(viz, inputs);
}
export interface ColumnTypePair {
  name: string;
  type: string;
}
