import { CsvRowsSchema } from "@fscrypto/domain/query-run";
import { TableState } from "@fscrypto/table";
import { Input, Text, Tooltip } from "@fscrypto/ui";
import { Button } from "@fscrypto/ui";
import clsx from "clsx";
import { Database, HardDrive, SearchIcon, Timer } from "lucide-react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Fragment } from "react";

export const QueryTableFooter = ({
  table,
  csvData,
  duration,
  filter,
  setFilter,
  theme,
}: {
  table: TableState<any>;
  csvData: CsvRowsSchema;
  duration: string;
  filter: string;
  setFilter: (filter: string) => void;
  theme: "light" | "dark";
}) => {
  return (
    <div
      className={clsx("flex justify-between p-2 text-content", {
        light: theme === "light",
        dark: theme === "dark",
      })}
    >
      <Filter filter={filter} setFilter={setFilter} />
      <PaginationControls table={table} />
      <TableInformation csvData={csvData} duration={duration} />
    </div>
  );
};

interface TableInformationProps {
  csvData: CsvRowsSchema;
  handleTranspose?: () => void;
  duration: string;
}

const TableInformation = ({ csvData, duration }: TableInformationProps) => {
  return (
    <div className="flex items-center gap-x-3 px-2">
      <Tooltip content="Rows" side="top">
        <div className="flex gap-x-1">
          <Database className="size-4 text-primary/80" />
          <Text size="xs">{csvData.length}</Text>
        </div>
      </Tooltip>
      <Tooltip content="Data Size" side="top">
        <div className="flex gap-x-1">
          <HardDrive className="size-4 text-primary/80" />
          <Text size="xs">{getObjectSizeAsString(csvData)}</Text>
        </div>
      </Tooltip>
      <Tooltip content="Duration" side="top">
        <div className="flex items-center gap-x-1">
          <Timer className="size-4 text-primary/80" />
          <Text size="sm">{duration}</Text>
        </div>
      </Tooltip>
    </div>
  );
};

const Filter = ({ filter, setFilter }: { filter: string; setFilter: (filter: string) => void }) => {
  return (
    <div className="flex items-center gap-x-3">
      <Input
        prefix={<SearchIcon />}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        size="sm"
        placeholder="Search"
      />
    </div>
  );
};

// Function to generate page numbers and ellipses
const generatePageNumbers = (currentPage: number, pageCount: number): (number | string)[] => {
  const pages: (number | string)[] = [];

  const pagingShowLowElipsis = currentPage > 3;
  const pagingShowHighElipsis = currentPage < pageCount - 2;
  const prevPageNumber = currentPage - 1;
  const nextPageNumber = currentPage + 1;

  if (pageCount <= 5) {
    for (let i = 1; i <= pageCount; i++) {
      pages.push(i);
    }
  } else {
    pages.push(1);
    if (pagingShowLowElipsis) pages.push("...");
    if (prevPageNumber > 1 && prevPageNumber < pageCount) pages.push(prevPageNumber);
    if (currentPage !== 1 && currentPage !== pageCount) pages.push(currentPage);
    if (nextPageNumber > 1 && nextPageNumber < pageCount) pages.push(nextPageNumber);
    if (pagingShowHighElipsis) pages.push("...");
    pages.push(pageCount);
  }

  return pages;
};

const PaginationControls = ({ table }: { table: TableState<any> }) => {
  const { pageIndex } = table.getState().pagination;
  const currentPage = pageIndex + 1;

  const pages = generatePageNumbers(currentPage, table.getPageCount());

  return (
    <div className="flex items-center space-x-2">
      <PrevButton onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />

      {pages.map((page, index) => (
        <Fragment key={index}>
          {page === "..." ? (
            <span>...</span>
          ) : (
            <Button
              size="sm"
              onClick={() => table.setPageIndex((page as number) - 1)}
              disabled={currentPage === page}
              variant={currentPage === page ? "outline" : "ghost"}
            >
              {page}
            </Button>
          )}
        </Fragment>
      ))}

      <NextButton onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
    </div>
  );
};

const NextButton = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => {
  if (disabled) return null;
  return (
    <Button size="sm" onClick={onClick} disabled={disabled} variant={"ghost"}>
      {/* <span>Next</span> */}
      <ChevronRight className="h-4 w-4" />
    </Button>
  );
};

const PrevButton = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => {
  if (disabled) return null;
  return (
    <Button size="sm" onClick={onClick} disabled={disabled} variant="ghost">
      {/* <span>Previous</span> */}
      <ChevronLeft className="h-4 w-4" />
    </Button>
  );
};

function getObjectSizeAsString(obj: any) {
  let str = null as string | null;
  if (typeof obj === "string") {
    // If obj is a string, then use it
    str = obj;
  } else {
    // Else, make obj into a string
    str = JSON.stringify(obj);
  }
  // Get the length of the Uint8Array
  const bytes = new TextEncoder().encode(str).length;

  if (bytes < 1024) return bytes + "B";
  else if (bytes < 1048576) return (bytes / 1024).toFixed(0) + "KB";
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(0) + "MB";
  else return (bytes / 1073741824).toFixed(0) + "GB";
}
